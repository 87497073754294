import React from 'react';
import bgImage from '../../assets/images/bg/02.png';
import {Col, Container, Row} from "reactstrap";

const HowItWorksSection = () => {
    return (
        <section className="custom-py-2 position-relative bg-dark" data-bg-img={bgImage}>
            <div className="container pe-8 ps-8">
                <div className="row justify-content-center text-center">
                    <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">
                        <div className="mb-8">
                            <h2 className="mt-3 font-w-5 mb-0 text-white">NFT Tiers: Investment Perks</h2>
                        </div>
                    </div>
                </div>
                <Container>
                    <Row className="align-items-start text-center">
                        <Col lg={4} md={6} className="mb-8 mb-lg-0 text-white">
                            <div>
                                <div>
                                    <span className="badge badge-primary-soft ic-2x p-2">
                                        TIER 1
                                    </span>
                                </div>
                                <h2 className="mt-3 mb-0">1 ETH For Basic Access</h2>
                            </div>
                        </Col>
                        <Col lg={4} md={6} className="mb-8 mb-lg-0 text-white">
                            <div>
                                <div>
                                    <span className="badge badge-primary-soft ic-2x p-2">
                                        TIER 2
                                    </span>
                                </div>
                                <h2 className="mt-3 mb-0">2 ETH For Quarterly Updates <br /> </h2>
                            </div>
                        </Col>
                        <Col lg={4} md={6} className="mb-8 mb-lg-0 text-white">
                            <div>
                                <div>
                                    <span className="badge badge-primary-soft ic-2x p-2">
                                        TIER 3
                                    </span>
                                </div>
                                <h2 className="mt-3 mb-0">3 ETH For Premium Insights and Team Interaction</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="shape-1" style={{height: '200px', overflow: 'hidden'}}>
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
                    <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
                          style={{stroke: 'none', fill: '#fff'}}></path>
                </svg>
            </div>
        </section>
    );
}

export default HowItWorksSection;
