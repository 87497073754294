import {Route, Routes} from 'react-router-dom';
import './App.css';
import "react-toastify/dist/ReactToastify.css";
import Footer from './Layout/Footer/Footer';
import './Vendor.js'
import React, {Fragment} from 'react';
import Home from './pages/home';
import {ToastContainer} from 'react-toastify';
import Scrolltop from './Layout/Header/BackToTop';
import Header from "./Layout/Header/Header";
import {Helmet} from "react-helmet";

function App() {
    return (<Fragment>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
        />
        <div className="page-wrapper">
            <Header/>
            <Helmet>
                <title>Tarry Fund</title>
            </Helmet>
            <Routes>
                <Route exact path="/" element={<Home />}/>
            </Routes>
            <Footer/>
            <Scrolltop/>
        </div>
    </Fragment>);
}

export default App;
