import React from 'react'
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
    return (
        <footer className="py-5 bg-primary position-relative" data-bg-img="../../assets/images/bg/03.png">
            <Container>
                <Row className="text-white text-center">
                    <Col>
                        Copyright 2024 Tarry Fund All Rights Reserved
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
