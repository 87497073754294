import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import tarryWhiteLogo from '../../assets/images/tarry_white_logo.png';

const Header = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = (document.documentElement && document.documentElement.scrollTop) ||
                document.body.scrollTop;
            if (scrollTop > 100) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={"site-header  navbar-dark"}>
            <div id="header-wrap" className={`position-absolute tarry-header w-100 z-index-1 ${(visible) ? "fixed-header " : ""}`}>
                <div className="container">
                    <div className="row">
                        {/*menu start*/}
                        <div className="col d-flex align-items-center justify-content-between">
                            <div className="tarry-logo">
                                <Link className="navbar-brand  logo text-white mb-0" to="/">
                                    <img src={tarryWhiteLogo}  className="img-fluid" alt="TARRY WHITE LOGO" />
                                </Link>
                            </div>
                            <div className="social-icons">
                                <div className="social-icon btn btn-outline-light">
                                    <Link to="#">
                                        <i className="fa-brands fa-twitter"/>
                                    </Link>
                                </div>
                                <div className="social-icon btn btn-outline-light">
                                    <Link to="#">
                                        <i className="fa-brands fa-discord"/>
                                    </Link>
                                </div>
                                <div className="social-icon btn btn-outline-light">
                                    <Link to="#">
                                        <i className="fa-solid fa-book"></i><span>Docs</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/*menu end*/}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
